<template>
  <section class="banner-slider-block" :class="{ 'md:items-center': experts.length <= 1 }">
    <NuxtImg
      v-if="currentZone?.slug"
      :src="GEOGRAPHIC_ZONE_BACKGROUND_IMAGES[currentZone.slug]"
      class="bg-image"
      ratio="4:1"
      alt="continent-image"
      loading="lazy"
    />
    <div class="mb-[24px] flex flex-row items-center px-[16px] md:mb-[40px] md:px-16">
      <Icon
        v-if="geographicZoneIcon"
        :name="geographicZoneIcon"
        width="40px"
        height="40px"
        class="mr-5 md:flex"
      />
      <!-- eslint-disable vue/no-v-html -->
      <h2
        class="inline text-xl font-semibold md:text-[40px]"
        v-html="replaceHighlightHtml(computedTitle)"
      />
      <!-- eslint-enable vue/no-v-html -->
      <div class="ml-auto items-center">
        <NuxtLink
          v-if="link"
          class="link hidden px-10 text-xl font-semibold md:flex"
          :href="link.slug"
          >{{ link.name }}</NuxtLink
        >
        <CarouselArrows
          v-if="experts.length > 1 && carousel && expertsWithGeoZone"
          class="hidden md:flex"
          :can-move-left="true"
          :can-move-right="true"
          @prev="carousel?.prevSlide"
          @next="carousel?.nextSlide"
        />
      </div>
    </div>
    <div v-if="zones && zones.length > 0" class="dropdown-container">
      <div class="dropdown-item zones">
        <!-- eslint-disable vue/no-v-html -->
        <span
          class="title-mobile"
          v-html="replaceHighlightHtml('Je choisis ma zone ** dans le monde**')"
        ></span>
        <FiltersDropdown
          v-model:value="currentZoneOption"
          :options="zoneOptions"
          @update:value="emit('update-zone', getOptionValue($event) as GeographicZoneSlugAlt)"
        />
      </div>
      <div v-if="currentZone" class="dropdown-item">
        <span class="title">Pays :</span>
        <!-- eslint-disable vue/no-v-html -->
        <div class="title-mobile" v-html="replaceHighlightHtml('Découvrir **nos experts**')"></div>
        <!-- eslint-enable vue/no-v-html -->

        <FiltersDropdown
          v-model:value="currentCountryOption"
          :options="countriesOptions"
          @update:value="emit('update-country', getOptionValue($event))"
        />
      </div>
    </div>
    <div
      class="flex flex-col md:mb-0 md:mb-8 md:flex md:grid-cols-3 md:flex-row md:justify-center md:gap-10 md:gap-4"
      :class="{ 'md:grid': experts.length > 1 }"
    >
      <div
        v-if="currentExpert"
        class="order-2 flex w-[100vw] flex-col items-center gap-3 px-4 md:order-1 md:w-auto md:items-start md:gap-4 md:pl-16"
      >
        <span class="text-lg font-semibold md:text-3xl">
          {{ currentExpert.attributes.firstName }}, expert{{
            currentExpert.attributes.isFemale ? 'e' : ''
          }}
          {{ currentExpert.attributes.expertise }}
        </span>
        <span class="text-base md:text-xl">
          {{ currentExpert.attributes.speech }}
        </span>
        <NuxtLink
          class="btn-primary mt-6 w-fit md:mb-8 md:mt-auto"
          :to="`/nos-conseillers/${currentExpert.attributes.slug}`"
          >Découvrir</NuxtLink
        >
      </div>
      <Carousel
        ref="carousel"
        class="order-1 col-span-2 md:order-2"
        loop
        :slides-data="
          expertsWithGeoZone.map(expert => ({
            id: expert.id,
            isActive: expert === expertsWithGeoZone[carousel?.currentIndex ?? 0],
            imageUrl: expert.attributes.image?.data?.attributes.url,
            title: expert.attributes.firstName,
            geographicZoneIcon: expert.geographicZoneIcon
          }))
        "
        :slide-component="ExpertSlide"
        @on-slide-change="onSlideClickOrChange"
      />
    </div>
  </section>
</template>

<script lang="ts" setup>
import { getGeographicZonePicto } from '@/lib/getGeographicZonePicto';
import {
  GEOGRAPHIC_ZONE_BACKGROUND_IMAGES,
  GEOGRAPHIC_ZONE_ICONS_ALT,
  GeographicZoneSlugAlt
} from '@/lib/variables';

import { Country } from '@/lib/strapi-types/Country';
import { Expert } from '@/lib/strapi-types/Expert';

import Carousel from '../Carousel.vue';
import ExpertSlide from '../ExpertSlide.vue';

import { DropdownOption } from '@/lib/types/dropdown';

const props = defineProps<{
  title: string;
  experts: Expert[];
  link?: { slug: string; name: string };
  zones?: Zone[];
  currentZone?: Zone;
  countries?: Country[];
  currentCountry?: Country;
}>();

const emit = defineEmits<{
  (event: 'update-zone', zone?: GeographicZoneSlugAlt): void;
  (event: 'update-country', countrySlug?: string): void;
}>();

const carousel = ref<InstanceType<typeof Carousel> | null>(null);

function onSlideClickOrChange(slide: any) {
  const slideIndex = expertsWithGeoZone.value.findIndex(expert => expert.id === slide.id);
  if (carousel.value) {
    carousel.value.currentIndex = slideIndex;
  }
  currentExpertIndex.value = slideIndex;
}

const computedTitle = computed(() => {
  if (props.currentZone) {
    if (props.experts.length > 1)
      return `Découvrez nos ${props.experts.length} experts **${props.currentCountry?.attributes.name ?? props.currentZone.type}**`;
    else
      return `Découvrez notre expert **${props.currentCountry?.attributes.name ?? props.currentZone.type}**`;
  }
  return props.title;
});

const geographicZoneIcon = computed(() => {
  return props.currentZone?.type ? GEOGRAPHIC_ZONE_ICONS_ALT[props.currentZone?.type] : undefined;
});

const zoneOptions = computed<DropdownOption[]>(() => {
  const defaultOptions = { label: 'Tous', value: 'default' };
  if (!props.zones) {
    return [defaultOptions];
  }

  return [
    defaultOptions,
    ...props.zones
      .map(zone => ({
        label: zone.type,
        value: zone.type,
        slug: zone.slug
      }))
      .sort((item1, item2) => item1.label.localeCompare(item2.label))
  ];
});

const getOptionValue = (option: DropdownOption) =>
  option.value === 'default' ? undefined : option.value;

const countriesOptions = computed<DropdownOption[]>(() => {
  const defaultOptions = { label: 'Tous', value: 'default' };
  if (!props.countries) {
    return [defaultOptions];
  }

  return [
    defaultOptions,
    ...props.countries.map(country => ({
      label: country.attributes.name,
      value: country.attributes.slug,
      slug: country.attributes.slug
    }))
  ];
});

const currentCountryOption = ref(countriesOptions.value[0]);
const currentZoneOption = ref(zoneOptions.value[0]);

watch(countriesOptions, () => {
  currentCountryOption.value = countriesOptions.value[0];
});

const expertsWithGeoZone = computed(() =>
  props.experts.map(expert => {
    const country = expert.attributes.countries?.data[0];
    const geographicZoneSlug =
      country?.attributes.geographicZones?.data[0]?.attributes.slug ??
      expert.attributes.geographicZones?.data[0]?.attributes.slug;
    const countrySlugs = country ? [country.attributes.slug] : undefined;
    return {
      ...expert,
      geographicZoneIcon:
        geographicZoneSlug && getGeographicZonePicto({ geographicZoneSlug, countrySlugs })
    };
  })
);

const currentExpertIndex = ref(0);

const currentExpert = computed(() => {
  return expertsWithGeoZone.value[currentExpertIndex.value];
});
</script>

<style scoped lang="scss">
@use '$/spacings.scss';
@use '$/breakpoints.scss';
@use '$/typography.scss';
@use '$/button.scss';
@use '$/misc.scss';
@use '$/border-radius.scss';

$desktop-element-width: 280px;
$desktop-element-height: 427px;

.banner-slider-block {
  position: relative;
  flex-direction: column;
  margin-bottom: spacings.$desktop-vertical-md;

  &.zone {
    align-items: center;
  }

  .bg-image {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  .dropdown-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 10px 124px 20px;

    .dropdown-item {
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;

      font-weight: 600;

      .title {
        display: block;
        font-size: 16px;
        white-space: nowrap;
      }

      .title-mobile {
        display: none;
      }

      :deep(.drop-down-button) {
        gap: 5px;
        justify-content: space-between;

        width: 195px;
        padding: 10px 12px;

        border-radius: border-radius.$small;

        .text-sm {
          font-size: 16px !important;
        }
      }

      &.zones {
        display: none;
      }
    }
  }
}

@media (width <= 768px) {
  .banner-slider-block {
    margin-bottom: spacings.$mobile-section;

    &.zone {
      align-items: flex-start;
    }

    .dropdown-container {
      align-items: start;
      margin-bottom: 24px;
      padding: 0 16px;

      .dropdown-item {
        flex-direction: column;
        align-items: start;
        width: 100%;

        &.zones {
          display: flex;
        }

        .title {
          display: none;
        }

        .title-mobile {
          display: flex;
          gap: 4px;
          font-size: 18px;
        }
      }
    }
  }
}
</style>
